<script setup>
import { MSkeleton } from '@ca-crowdfunding/makuake-ui-n';
import ScrollContainer from '@/components/common/ScrollContainer';
import SectionHeader from '@/components/home/SectionHeader';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useFetchProjects from '@/composables/common/fetchProjects';
import useGaEvent from '@/composables/common/gaEvent';
import useIntersection from '@/composables/common/intersection';
import { HISTORY_DISPLAY_NUMBER } from '@/consts/home';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';
import watchedProjects from '@/modules/watchedProjects';

const watchedIds = watchedProjects.listIds();
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { fetchWatchedProjects, loading, projects } = useFetchProjects();
const { setupIntersection } = useIntersection();

setupIntersection(fetchWatchedProjects);
</script>

<template>
  <section v-if="watchedIds.length" class="history-section">
    <SectionHeader href="/favorite/watched" label="history" title="閲覧履歴" />
    <ScrollContainer class="gap-2 sm:gap-3" tag="ul">
      <template v-if="loading">
        <li
          v-for="key in Math.min(watchedIds.length, HISTORY_DISPLAY_NUMBER)"
          :key
          class="flex-none w-1/6 min-w-28 max-w-48"
        >
          <MSkeleton aspect-ratio="16 / 9" rounded />
        </li>
      </template>
      <li
        v-for="(project, key) in projects"
        v-else
        :key
        class="flex-none w-1/6 min-w-28 max-w-48"
      >
        <a
          :href="project.url"
          @click="
            () => {
              sendClickEvent('watched', key);
              sendSelectContentEvent(project.id, SELECT_CONTENT_TYPES.PROJECT);
            }
          "
        >
          <ThumbnailCard
            :alt="project.title"
            aspect-ratio="16 / 9"
            height="63"
            height-sm="107"
            :src="project.image_url"
            width="112"
            width-md="190"
          />
        </a>
      </li>
    </ScrollContainer>
  </section>
</template>

<style scoped>
.thumbnail-card {
  transition: opacity 0.2s;
}

a:hover .thumbnail-card {
  opacity: 0.72;
}
</style>
