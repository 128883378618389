<script setup>
import { format, fromUnixTime } from 'date-fns';
import {
  MIcon,
  MSkeleton,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import SuccessBar from '@/components/common/SuccessBar';
import OwnerAvatar from '@/components/home/OwnerAvatar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  index: {
    type: Number,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  project: {
    type: Object,
    default: null,
  },
  trackingId: {
    type: String,
  },
});

const { xs } = useDisplay();
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();

const onClick = () => {
  sendClickEvent(props.trackingId, props.index);
  sendSelectContentEvent(props.project.id, SELECT_CONTENT_TYPES.PROJECT);
};
</script>

<template>
  <template v-if="loading">
    <MSkeleton aspect-ratio="16 / 9" class="mb-4" />
    <div class="flex gap-2 sm:gap-3">
      <MSkeleton rounded="full" size="32" sm="40" />
      <div class="grow flex flex-col gap-3 sm:gap-4">
        <MSkeleton :lines="xs ? 2.4 : 2" size="x-small" sm="medium" />
        <div class="flex items-center justify-between">
          <slot name="loader.subtext">
            <MSkeleton class="grow" lines="0.5" size="small" sm="large" />
          </slot>
          <MIcon
            class="shrink-0"
            color="disabled"
            name="heart"
            size="small"
            sm="medium"
          />
        </div>
        <slot name="loader.status">
          <div class="flex items-center gap-2 -mt-0.5 sm:gap-4">
            <MIcon
              class="-mr-1.5 sm:-mr-3"
              size="11"
              sm="small"
              color="disabled"
              name="time"
            />
            <MSkeleton
              class="w-5 sm:w-7"
              lines="1"
              size="3x-small"
              sm="small"
            />
            <SuccessBar />
            <MSkeleton
              class="w-7 sm:w-10"
              lines="1"
              size="3x-small"
              sm="small"
            />
          </div>
        </slot>
      </div>
    </div>
  </template>
  <a
    v-else-if="project"
    class="project-item"
    :href="project.url"
    @click="onClick"
  >
    <article class="contents">
      <ThumbnailCard
        v-if="project.image_url"
        aspect-ratio="16 / 9"
        class="mb-4"
        height="126"
        height-sm="252"
        :src="project.image_url"
        width="224"
        width-sm="448"
      >
        <template #text>
          <slot name="thumbnail.append" :project />
        </template>
      </ThumbnailCard>
      <div class="flex gap-2 sm:gap-3">
        <OwnerAvatar
          v-if="project.user?.image_url"
          :alt="project.user.name"
          :is-selected-owner="project.user.is_selected_user"
          size="32"
          sm="40"
          :src="project.user.image_url"
        />
        <div class="grow flex flex-col gap-3 sm:gap-4">
          <MText
            :class="xs ? 'min-h-14' : 'min-h-[2.75rem]'"
            :max-lines="xs ? 3 : 2"
            size="x-small"
            sm="medium"
            tag="h3"
          >
            {{ project.title }}
          </MText>
          <div class="flex items-center justify-between">
            <slot name="subtext" :project>
              <MText size="small" sm="large" tag="p" weight="bold">
                {{
                  project.collected_money?.toLocaleString('ja-JP', {
                    currency: 'JPY',
                    style: 'currency',
                  })
                }}
              </MText>
            </slot>
            <LikeButton
              :index
              :project-id="project.id"
              :size="xs ? 'small' : 'medium'"
            />
          </div>
          <slot name="status" :project>
            <div class="flex items-center gap-2 -mt-0.5 sm:gap-4">
              <MIcon
                class="-mr-1.5 sm:-mr-3"
                size="11"
                sm="small"
                color="secondary"
                name="time"
              />
              <MText
                v-if="project.time_left_label"
                size="3x-small"
                sm="small"
                tag="time"
                :datetime="
                  project.expiration_date
                    ? format(
                        fromUnixTime(project.expiration_date || 0),
                        'yyyy-MM-dd',
                      )
                    : ''
                "
              >
                {{ project.time_left_label }}</MText
              >
              <SuccessBar :percent="project.percent" />
              <MText size="3x-small" sm="small" tag="span"
                >{{ project.percent }}%</MText
              >
            </div>
          </slot>
        </div>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.project-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}

.min-h-\[2\.75rem\] {
  min-height: 2.75rem; /* 44px */
}
</style>
