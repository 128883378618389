<script setup>
import { inject } from 'vue';
import { MText } from '@ca-crowdfunding/makuake-ui-n';
import Breadcrumbs from '@/components/discover/Breadcrumbs';

const heading = inject('heading');
</script>

<template>
  <header class="flex flex-col gap-6 mb-6">
    <Breadcrumbs />
    <MText size="x-large" sm="3x-large" tag="h2" weight="bold">
      {{ heading }}
    </MText>
  </header>
  <RouterView />
  <p class="mt-12">TODO: AIからのおすすめ</p>
</template>
