<script setup>
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const { fetchComingSoonProjects, loading, pagination, projects } =
  useFetchProjects();
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="coming-soon-view"
    :loading
    :projects
    @fetch="fetchComingSoonProjects({ page: pagination.page })"
  >
    <template #[`project.loader.subtext`]>
      <div />
    </template>
    <template #[`project.loader.status`]>
      <div class="hidden" />
    </template>
    <template #[`project.subtext`]>
      <div />
    </template>
    <template #[`project.status`]>
      <div class="hidden" />
    </template>
  </ViewBase>
</template>
