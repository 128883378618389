<script setup>
import { onBeforeMount, provide, ref } from 'vue';
import { MCol, MRow } from '@ca-crowdfunding/makuake-ui-n';
import FriendInviteTicketAcquireModal from '@/components/friendinvite/FriendInviteTicketAcquireModal';
import ActivityReportSection from '@/components/home/ActivityReportSection';
import ApplicationSection from '@/components/home/ApplicationSection';
import BannerArea from '@/components/home/BannerArea';
import BlogSection from '@/components/home/BlogSection';
import CatchCopy from '@/components/home/CatchCopy';
import CategorySection from '@/components/home/CategorySection';
import ComingSoonSection from '@/components/home/ComingSoonSection';
import EventSection from '@/components/home/EventSection';
import HeroArea from '@/components/home/HeroArea';
import HistorySection from '@/components/home/HistorySection';
import IntroductionSection from '@/components/home/IntroductionSection';
import NewArrivalsSection from '@/components/home/NewArrivalsSection';
import PickupSection from '@/components/home/PickupSection';
import PopularSection from '@/components/home/PopularSection';
import RankingSection from '@/components/home/RankingSection';
import RecommendedSection from '@/components/home/RecommendedSection';
import RemindedSection from '@/components/home/RemindedSection';
import ServiceSection from '@/components/home/ServiceSection';
import TagSection from '@/components/home/TagSection';
import TaggedSection from '@/components/home/TaggedSection';
import { TAGS } from '@/consts/home';
import UA from '@/modules/UserAgent';

const device = ref('pc');
provide('device', device);

onBeforeMount(async () => {
  const ua = new UA();
  await ua.init();

  if (ua.isSP()) {
    device.value = 'sp';
  }

  if (ua.isNative()) {
    device.value = 'app';
    return;
  }

  const { braze } = window;

  if (braze) {
    braze.logCustomEvent('pageview_top', { timestamp: new Date() });
  }
});
</script>

<template>
  <div class="flex flex-col gap-10 md:mt-2">
    <CatchCopy />
    <HeroArea />
    <BannerArea />
    <CategorySection />
    <HistorySection />
    <RemindedSection />
    <RecommendedSection />
    <PickupSection />
    <RankingSection />
    <NewArrivalsSection />
    <ComingSoonSection />
    <PopularSection />
    <TaggedSection :tag="TAGS.MADE_IN_JAPAN" />
    <TaggedSection :tag="TAGS.RESTAURANT" />
    <TaggedSection :tag="TAGS.UTILITY_GOODS" />
    <TaggedSection :tag="TAGS.GADGET" />
    <TaggedSection :tag="TAGS.FASHION" />
    <TaggedSection :tag="TAGS.OUTDOOR" />
    <ApplicationSection />
    <TagSection />
    <MRow class="max-w-screen-lg" gap="10">
      <MCol cols="12" md="6"><ActivityReportSection /></MCol>
      <MCol cols="12" md="6"><BlogSection /></MCol>
    </MRow>
    <EventSection />
    <IntroductionSection />
    <ServiceSection />
  </div>
  <FriendInviteTicketAcquireModal />
</template>
