import { ref } from 'vue';
import api from '@/modules/api/php/good';

const likedIds = ref(/** @type {number[]} */ []);
const isLoaded = ref(false);

export default function useLikedProjectIds() {
  /**
   * @returns {Promise<void>}
   */
  const fetchIds = async () => {
    try {
      const res = await api.fetchGoods();
      if (res.data?.goods) {
        likedIds.value = res.data.goods.split(',').map(Number);
      } else if (res.data?.liked_project_ids) {
        likedIds.value = res.data.liked_project_ids;
      } else {
        likedIds.value = [];
      }
    } catch (_) {
      likedIds.value = [];
    } finally {
      isLoaded.value = true;
    }
  };

  const loadIdsFromCookie = () => {
    const cookieValue = api.getIds();
    // string[]なのでnumber[]に変換する
    likedIds.value = cookieValue.map(Number);
    isLoaded.value = true;
  };

  /**
   * @param {boolean} isLoggedIn
   */
  const loadLikedProjectIds = async isLoggedIn => {
    if (isLoggedIn) {
      await fetchIds();
    } else {
      loadIdsFromCookie();
    }
  };

  return {
    isLoaded,
    likedIds,
    loadLikedProjectIds,
  };
}
