<script setup>
import { format, fromUnixTime } from 'date-fns';
import { computed } from 'vue';
import {
  MIcon,
  MSkeleton,
  MText,
  useDisplay,
} from '@ca-crowdfunding/makuake-ui-n';
import LikeButton from '@/components/common/LikeButton';
import ProjectLabel from '@/components/common/ProjectLabel';
import RankLabel from '@/components/common/RankLabel';
import SuccessBar from '@/components/common/SuccessBar';
import OwnerAvatar from '@/components/home/OwnerAvatar';
import ThumbnailCard from '@/components/home/ThumbnailCard';
import useGaEvent from '@/composables/common/gaEvent';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const props = defineProps({
  index: {
    type: Number,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  project: {
    type: Object,
    default: null,
  },
  rank: {
    type: Number,
  },
  trackingId: {
    type: String,
  },
});

const { xs } = useDisplay();
const { sendClickEvent, sendSelectContentEvent } = useGaEvent();

const isWider = computed(() => !xs.value || (xs.value && props.rank <= 10));

const onClick = () => {
  sendClickEvent(props.trackingId, props.index);
  sendSelectContentEvent(props.project.id, SELECT_CONTENT_TYPES.PROJECT);
};
</script>

<template>
  <template v-if="loading">
    <MSkeleton aspect-ratio="16 / 9" class="mb-4" />
    <div class="flex gap-3 px-1 sm:px-0">
      <MSkeleton v-if="isWider" rounded="full" size="40" />
      <div class="grow flex flex-col gap-4">
        <MSkeleton
          :lines="isWider ? 2 : 2.4"
          :size="isWider ? 'medium' : '2x-small'"
        />
        <div class="flex items-center justify-between">
          <MSkeleton
            class="grow"
            lines="0.5"
            :size="isWider ? 'large' : 'small'"
          />
          <MIcon
            class="shrink-0"
            color="disabled"
            name="heart"
            :size="isWider ? 'medium' : 'small'"
          />
        </div>
        <div
          class="flex items-center"
          :class="isWider ? ['gap-4', '-mt-0.5'] : ['gap-2', '-mt-1']"
        >
          <MIcon
            :class="isWider ? '-mr-3' : '-mr-1.5'"
            :size="isWider ? 'small' : 11"
            color="disabled"
            name="time"
          />
          <MSkeleton
            :class="isWider ? 'w-7' : 'w-5'"
            lines="1"
            :size="isWider ? 'small' : '3x-small'"
          />
          <SuccessBar />
          <MSkeleton
            :class="isWider ? 'w-10' : 'w-7'"
            lines="1"
            :size="isWider ? 'small' : '3x-small'"
          />
        </div>
      </div>
    </div>
  </template>
  <a
    v-else-if="project"
    class="project-item"
    :href="project.url"
    @click="onClick"
  >
    <article class="contents">
      <ThumbnailCard
        v-if="project.image_url"
        aspect-ratio="16 / 9"
        class="mb-4"
        :height="isWider ? 252 : 126"
        :src="project.image_url"
        :width="isWider ? 448 : 224"
      >
        <template #text>
          <RankLabel :rank :size="isWider ? 'medium' : 'small'" />
        </template>
      </ThumbnailCard>
      <div class="flex gap-3 px-1 sm:px-0">
        <OwnerAvatar
          v-if="isWider && project.user?.image_url"
          :alt="project.user.name"
          :is-selected-owner="project.user.is_selected_user"
          size="40"
          :src="project.user.image_url"
        />
        <div class="grow flex flex-col gap-4">
          <MText
            :class="isWider ? 'min-h-[2.75rem]' : 'min-h-[3.1875rem]'"
            :max-lines="isWider ? 2 : 3"
            :size="isWider ? 'medium' : '2x-small'"
            tag="h3"
          >
            {{ project.title }}
          </MText>
          <div class="flex items-center justify-between">
            <MText :size="isWider ? 'large' : 'small'" tag="p" weight="bold">
              {{
                project.collected_money?.toLocaleString('ja-JP', {
                  currency: 'JPY',
                  style: 'currency',
                })
              }}
            </MText>
            <LikeButton
              :index
              :project-id="project.id"
              :size="isWider ? 'medium' : 'small'"
            />
          </div>
          <div
            class="flex items-center"
            :class="isWider ? ['gap-4', '-mt-0.5'] : ['gap-2', '-mt-1']"
          >
            <MIcon
              :class="isWider ? '-mr-3' : '-mr-1.5'"
              :size="isWider ? 'small' : 11"
              color="secondary"
              name="time"
            />
            <MText
              v-if="project.time_left_label"
              :size="isWider ? 'small' : '3x-small'"
              tag="time"
              :datetime="
                project.expiration_date
                  ? format(fromUnixTime(project.expiration_date), 'yyyy-MM-dd')
                  : ''
              "
            >
              {{ project.time_left_label }}</MText
            >
            <SuccessBar :percent="project.percent" />
            <MText :size="isWider ? 'small' : '3x-small'" tag="span"
              >{{ project.percent }}%</MText
            >
          </div>
          <ProjectLabel v-if="project.is_new_store_opening" store />
        </div>
      </div>
    </article>
  </a>
</template>

<style lang="scss" scoped>
.project-item:hover {
  text-decoration: none;

  &:not(:has(.like-button:hover)) .thumbnail-card {
    opacity: 0.72;
  }
}

.thumbnail-card {
  transition: opacity 0.2s;
}

.min-h-\[2\.75rem\] {
  min-height: 2.75rem; /* 44px */
}

.min-h-\[3\.1875rem\] {
  min-height: 3.1875rem; /* 51px */
}
</style>
