import { computed, reactive, ref } from 'vue';
import useRandomPicker from '@/composables/randomPicker';
import useGaEvent from '@/composables/common/gaEvent';
import useLikedProjectIds from '@/composables/common/likedProjectIds';
import useSnackbar from '@/composables/common/snackbar';
import useMyTag from '@/composables/favorite/myTag';
import {
  KIND_ONGOING,
  KIND_IN_STORE,
  PER_PAGE,
  SORT_POPULAR,
  SORT_OPTION_KEY_POPULAR,
} from '@/consts/discover';
import { DEFAULT_ERROR_MESSAGE } from '@/consts/error';
import GA_EVENTS from '@/consts/gaEvents';
import {
  HISTORY_DISPLAY_NUMBER,
  PROJECT_DISPLAY_NUMBER,
  RANKING_DISPLAY_NUMBER,
} from '@/consts/home';
import RANKING_CATEGORIES from '@/consts/ranking';
import api from '@/modules/api/v2/projects';
import rankApi from '@/modules/api/v2/rankings';
import returnApi from '@/modules/api/v2/returns';
import searchApi from '@/modules/api/php/projects';
import remindApi from '@/modules/api/php/top/projectend';
import recommendApi from '@/modules/api/php/recommended';
import loggedInStatus from '@/modules/isLoggedinStatus';
import watchedProjects from '@/modules/watchedProjects';

export default function useFetchProjects() {
  const filter = reactive({
    isOngoing: false,
    isInStore: false,
    sort: '',
  });
  const loading = ref(true);
  const pagination = ref({});
  const projects = ref([]);
  const userId = ref();

  const { isLoaded: isLoadedFavorite, likedIds } = useLikedProjectIds();
  const { isLoaded: isLoadedMyTags, myTags } = useMyTag();
  const { randomPick } = useRandomPicker();
  const { showError } = useSnackbar();
  const { sendViewContentListEvent } = useGaEvent();

  /**
   * @param {string} id GAイベントとして送信するID
   * @param {string} name GAイベントとして送信する名前
   * @param {() => Promise<Object>} action fetch関数
   */
  const fn = async (id, name, action) => {
    loading.value = true;
    try {
      const data = await action();

      pagination.value = data?.pagination || {};
      if (data?.pagination?.page > 1) {
        projects.value = [...projects.value, ...data.projects];
      } else {
        projects.value = data.projects;
      }

      if (!data?.projects?.length) return;
      sendViewContentListEvent(
        `${id}${data?.pagination?.page ? `_${data.pagination.page}` : '_1'}`,
        name,
      );
    } catch (e) {
      pagination.value = {};
      projects.value = [];
      showError({ message: DEFAULT_ERROR_MESSAGE });
    } finally {
      loading.value = false;
    }
  };

  // データがない場合のレスポンス
  const noData = { data: { projects: [] } };

  /** キーワード検索によってプロジェクトを取得する */
  const fetchProjectsByKeyword = async (
    keyword,
    { page = 1, perPage = PER_PAGE } = {},
  ) => {
    await fn(
      GA_EVENTS.FETCH_PROJECTS_BY_KEYWORD.ID,
      GA_EVENTS.FETCH_PROJECTS_BY_KEYWORD.NAME,
      async () => {
        const { data } = await searchApi.fetchProjectsByKeyword(keyword, {
          page,
          perPage,
        });
        return data;
      },
    );
  };

  /** すべてのプロジェクトを取得する */
  const fetchAllProjects = async ({ page = 1, perPage = PER_PAGE } = {}) => {
    await fn(
      GA_EVENTS.FETCH_ALL_PROJECTS.ID,
      GA_EVENTS.FETCH_ALL_PROJECTS.NAME,
      async () => {
        const props = {
          page,
          per_page: perPage,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchProjects(props);
        return data;
      },
    );
  };

  /** プロジェクトの閲覧履歴を取得する */
  const fetchWatchedProjects = async ({
    page = 1,
    perPage = HISTORY_DISPLAY_NUMBER,
    withUser = false,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_WATCHED_PROJECTS.ID,
      GA_EVENTS.FETCH_WATCHED_PROJECTS.NAME,
      async () => {
        const ids = watchedProjects.listIds();
        if (!ids.length) return noData;

        const { data } = await api.fetchProjects({
          ids: ids.join(),
          page,
          per_page: perPage,
          with_user: withUser,
        });
        return data;
      },
    );
  };

  /** 気になるしたプロジェクトを取得する */
  const fetchLikedProjects = async ({ page = 1, perPage = PER_PAGE } = {}) => {
    await fn(
      GA_EVENTS.FETCH_LIKED_PROJECTS.ID,
      GA_EVENTS.FETCH_LIKED_PROJECTS.NAME,
      async () => {
        /** 気になるリストの取得完了を待機 */
        const waitForLoaded = () =>
          new Promise(resolve => {
            if (isLoadedFavorite.value) resolve(true);
            else setTimeout(() => resolve(waitForLoaded()), 500);
          });

        if (await waitForLoaded()) {
          // 気になるがない場合は空のデータを返す
          if (!likedIds.value.length) return noData;

          // すでに取得済みのプロジェクトを除外
          const remainingProjectIds = computed(() =>
            likedIds.value.filter(
              id => !projects.value.map(project => project.id).includes(id),
            ),
          );

          const ids = remainingProjectIds.value.slice(0, perPage);

          const { data } = await api.fetchProjects({
            ids: ids.join(),
            with_user: true,
          });
          data.pagination = {
            page,
            per_page: perPage,
            total: likedIds.value.length,
          };
          return data;
        }

        // エラーハンドリング用
        return undefined;
      },
    );
  };

  /** Myタグに紐づいたプロジェクトを取得する */
  const fetchMyTagProjects = async ({ page = 1, perPage = PER_PAGE } = {}) => {
    await fn(
      GA_EVENTS.FETCH_MY_TAG_PROJECTS.ID,
      GA_EVENTS.FETCH_MY_TAG_PROJECTS.NAME,
      async () => {
        /** Myタグの取得完了を待機 */
        const waitForLoaded = () =>
          new Promise(resolve => {
            if (isLoadedMyTags.value) resolve(true);
            else setTimeout(() => resolve(waitForLoaded()), 500);
          });

        if (await waitForLoaded()) {
          // Myタグがない場合は空のデータを返す
          if (!myTags.value.length) return noData;

          const { data } = await api.fetchProjectsByTagIds({
            kinds: KIND_ONGOING,
            page,
            per_page: perPage,
            tag_ids: myTags.value.map(tag => tag.id),
            with_user: true,
          });
          return data;
        }

        // エラーハンドリング用
        return undefined;
      },
    );
  };

  /** ユーザーに応じた終了間近のプロジェクトを取得する */
  const fetchRemindedProjects = async () => {
    await fn(
      GA_EVENTS.FETCH_REMINDED_PROJECTS.ID,
      GA_EVENTS.FETCH_REMINDED_PROJECTS.NAME,
      async () => {
        const userLoggedIn = loggedInStatus.isUserLoggedin();
        if (!userLoggedIn) return undefined;

        const { data } = await remindApi.fetchProjectsEnd();
        return data;
      },
    );
  };

  /** おすすめプロジェクトを取得する */
  const fetchRecommendedProjects = async () => {
    await fn(
      GA_EVENTS.FETCH_RECOMMENDED_PROJECTS.ID,
      GA_EVENTS.FETCH_RECOMMENDED_PROJECTS.NAME,
      async () => {
        const viewedProjectIds = watchedProjects.listIds().join();
        const { data } = await recommendApi.fetchRecommended({
          viewedProjectIds,
          withUser: true,
        });
        userId.value = data?.recommend_user_id;
        return data;
      },
    );
  };

  /** ピックアップ中のプロジェクトを取得する */
  const fetchPickupProjects = async ({ page = 1, perPage = PER_PAGE } = {}) => {
    await fn(
      GA_EVENTS.FETCH_PICKUP_PROJECTS.ID,
      GA_EVENTS.FETCH_PICKUP_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchPickup({
          page,
          per_page: perPage,
          sort: 'random',
          with_user: true,
        });
        return data;
      },
    );
  };

  /**
   * ランクインしたプロジェクトを取得する
   *  @param {string} tagGroup */
  const fetchRankedProjects = async ({
    tagGroup = RANKING_CATEGORIES[0].value,
    perPage = RANKING_DISPLAY_NUMBER,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_RANKED_PROJECTS.ID,
      GA_EVENTS.FETCH_RANKED_PROJECTS.NAME,
      async () => {
        const { data } = await rankApi.fetchRanking({
          limit: perPage,
          tag_group: tagGroup,
          with_returns: true,
          with_user: true,
        });
        // 共通処理でdata.projectsを操作するので新しいオブジェクトにする
        return {
          projects: data?.rankings?.length
            ? data.rankings.reduce((acc, cur) => {
                const { project } = cur;
                project.rank = cur.rank;
                acc.push(project);
                return acc;
              }, [])
            : [],
        };
      },
    );
  };

  /** 新着のプロジェクトを取得する */
  const fetchNewProjects = async ({
    page = 1,
    perPage = PER_PAGE,
    random = 0,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_NEW_PROJECTS.ID,
      GA_EVENTS.FETCH_NEW_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchNew({
          page,
          per_page: perPage,
          with_user: true,
        });
        if (random) {
          data.projects = data?.projects?.length
            ? randomPick(data.projects, random)
            : [];
        }
        return data;
      },
    );
  };

  /** もうすぐ開始するプロジェクトを取得する */
  const fetchComingSoonProjects = async ({
    page = 1,
    perPage = PER_PAGE,
    random = 0,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_COMING_SOON_PROJECTS.ID,
      GA_EVENTS.FETCH_COMING_SOON_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchComingSoon({
          page,
          per_page: perPage,
          with_user: true,
        });
        if (random) data.projects = randomPick(data.projects, random);
        return data;
      },
    );
  };

  /** 人気のプロジェクトを取得する */
  const fetchPopularProjects = async () => {
    await fn(
      GA_EVENTS.FETCH_POPULAR_PROJECTS.ID,
      GA_EVENTS.FETCH_POPULAR_PROJECTS.NAME,
      async () => {
        const { data } = await returnApi.fetchPopular({
          per_page: PROJECT_DISPLAY_NUMBER,
          with_returns: true,
          with_user: true,
        });
        data.projects = data?.returns?.length
          ? data.returns.reduce((acc, cur) => {
              // プロジェクトの重複を防ぐ
              if (!acc.some(project => project.id === cur.project.id)) {
                acc.push(cur.project);
              }
              return acc;
            }, [])
          : [];
        return data;
      },
    );
  };

  /**
   * 特定のタグがついたプロジェクトを取得する
   *  @param {number|number[]} tagIds 対象のタグIDまたはその配列 */
  const fetchProjectsByTagIds = async (
    tagIds,
    { page = 1, perPage = PER_PAGE } = {},
  ) => {
    await fn(
      `${GA_EVENTS.FETCH_PROJECTS_BY_TAG_IDS.ID}_${tagIds}`,
      GA_EVENTS.FETCH_PROJECTS_BY_TAG_IDS.NAME,
      async () => {
        const props = {
          page,
          per_page: perPage,
          tag_ids: tagIds,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchProjectsByTagIds(props);
        return data;
      },
    );
  };

  /** もうすぐ終了するプロジェクトを取得する */
  const fetchEndingSoonProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_ENDING_SOON_PROJECTS.ID,
      GA_EVENTS.FETCH_ENDING_SOON_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchEndingSoon({
          page,
          per_page: perPage,
          with_user: true,
        });
        return data;
      },
    );
  };

  /** 推奨実行者のプロジェクトを取得する */
  const fetchSelectedOwnersProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_SELECTED_OWNERS_PROJECTS.ID,
      GA_EVENTS.FETCH_SELECTED_OWNERS_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchSelected({
          page,
          per_page: perPage,
          with_user: true,
        });
        return data;
      },
    );
  };

  /** ふるさと納税型のプロジェクトを取得する */
  const fetchGovernmentProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_GOVERNMENT_PROJECTS.ID,
      GA_EVENTS.FETCH_GOVERNMENT_PROJECTS.NAME,
      async () => {
        const { data } = await api.fetchGovernment({
          page,
          per_page: perPage,
          with_user: true,
        });
        return data;
      },
    );
  };

  /** 応援購入総額が多い順にプロジェクトを取得する */
  const fetchMostFundedProjects = async ({
    page = 1,
    perPage = PER_PAGE,
  } = {}) => {
    await fn(
      GA_EVENTS.FETCH_MOST_FUNDED_PROJECTS.ID,
      GA_EVENTS.FETCH_MOST_FUNDED_PROJECTS.NAME,
      async () => {
        const props = { page, per_page: perPage, with_user: true };

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchMostFunded(props);
        data.projects = data.projects.map((project, index) => ({
          ...project,
          rank:
            ((data?.pagination?.page || 0) - 1) *
              (data?.pagination?.per_page || 0) +
            index +
            1,
        }));
        return data;
      },
    );
  };

  /**
   * 特定のカテゴリーがついたプロジェクトを取得する
   *  @param {string} categorySlug 対象のカテゴリースラッグ */
  const fetchProjectsByCategorySlug = async (
    categorySlug,
    { page = 1, perPage = PER_PAGE } = {},
  ) => {
    await fn(
      `${GA_EVENTS.FETCH_PROJECTS_BY_CATEGORY_SLUG.ID}_${categorySlug}`,
      GA_EVENTS.FETCH_PROJECTS_BY_CATEGORY_SLUG.NAME,
      async () => {
        const props = {
          category_code: categorySlug,
          page,
          per_page: perPage,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) {
          props.is_ongoing = true;
          kinds.push(KIND_ONGOING);
        }
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) {
          props.kinds = kinds.join();
          props.type = 'all';
        }

        const { data } = await api.fetchProjectsByCategoryCode(props);
        return data;
      },
    );
  };

  /**
   * 特定の地域のプロジェクトを取得する
   *  @param {number[]} locationIds 対象の地域IDの配列  */
  const fetchProjectsByLocationIds = async (
    locationIds,
    { page = 1, perPage = PER_PAGE } = {},
  ) => {
    await fn(
      `${GA_EVENTS.FETCH_PROJECTS_BY_LOCATION_IDS.ID}_${locationIds}`,
      GA_EVENTS.FETCH_PROJECTS_BY_LOCATION_IDS.NAME,
      async () => {
        const props = {
          location_ids: locationIds,
          page,
          per_page: perPage,
          with_user: true,
        };

        if (filter.sort === SORT_OPTION_KEY_POPULAR) props.sort = SORT_POPULAR;

        const kinds = [];
        if (filter.isOngoing) kinds.push(KIND_ONGOING);
        if (filter.isInStore) kinds.push(KIND_IN_STORE);
        if (kinds.length > 0) props.kinds = kinds.join();

        const { data } = await api.fetchProjectsByLocationIds(props);
        return data;
      },
    );
  };

  return {
    fetchAllProjects,
    fetchComingSoonProjects,
    fetchEndingSoonProjects,
    fetchGovernmentProjects,
    fetchLikedProjects,
    fetchMostFundedProjects,
    fetchMyTagProjects,
    fetchNewProjects,
    fetchPickupProjects,
    fetchPopularProjects,
    fetchProjectsByCategorySlug,
    fetchProjectsByKeyword,
    fetchProjectsByLocationIds,
    fetchProjectsByTagIds,
    fetchRankedProjects,
    fetchRecommendedProjects,
    fetchRemindedProjects,
    fetchSelectedOwnersProjects,
    fetchWatchedProjects,
    fn, // for unit test
    filter,
    loading,
    pagination,
    projects,
    userId,
  };
}
