<script setup>
import { inject, onBeforeMount, ref } from 'vue';
import { MDivider, MSnackbar, useDisplay } from '@ca-crowdfunding/makuake-ui-n';
import Navigation from '@/apps/Navigation';
import useLikedProjectIds from '@/composables/common/likedProjectIds';
import useSnackbar from '@/composables/common/snackbar';
import api from '@/modules/api/php/me';
import store from '@/store';

defineProps({
  featureFlag: {
    type: Boolean,
    default: false,
  },
});

const { mdAndUp } = useDisplay();
const { loadLikedProjectIds } = useLikedProjectIds();
const { action, message, snackbar, props } = useSnackbar();

const popularTags = inject('popularTags', ref([]));

popularTags.value = [
  { id: 203, name: '便利グッズ' },
  { id: 8, name: 'ガジェット' },
  { id: 121, name: 'レストラン' },
  { id: 180, name: '会員制' },
  { id: 66, name: 'フード' },
  { id: 60, name: 'グルメ' },
  { id: 158, name: '日本酒' },
  { id: 77, name: '肉' },
  { id: 175, name: 'ギフト' },
  { id: 81, name: 'テクノロジー' },
  { id: 184, name: '日本製' },
  { id: 129, name: '地域活性' },
  { id: 183, name: '伝統' },
  { id: 115, name: '文房具' },
  { id: 50, name: 'ファッション' },
  { id: 57, name: '腕時計' },
  { id: 53, name: '財布' },
  { id: 52, name: 'リュック' },
  { id: 134, name: 'アウトドア' },
  { id: 109, name: '子ども' },
];

onBeforeMount(async () => {
  const { data } = await api.fetchIsLogin();
  const isLoggedIn = data?.is_logined ?? false;
  store.setIsLogin(isLoggedIn);
  loadLikedProjectIds(isLoggedIn);
});
</script>

<template>
  <main class="flex gap-2">
    <Navigation v-if="mdAndUp" class="flex-none" :feature-flag />
    <article class="grow overflow-hidden p-6 pb-16 md:pt-8 md:pb-24">
      <RouterView />
    </article>
  </main>
  <MDivider />
  <MSnackbar v-model="snackbar" v-bind="props" @action="action">
    {{ message }}
  </MSnackbar>
</template>

<style lang="scss">
html {
  font-size: 100%;
}

#header {
  position: sticky;
  top: 0;
  z-index: 10000;

  .cb-header {
    position: static;
  }
}
</style>

<style scoped>
.navigation {
  height: calc(100vh - 65px);
  position: sticky;
  top: 65px;
  width: 224px;
}
</style>
