<script setup>
import { MText, MIcon } from '@ca-crowdfunding/makuake-ui-n';
import ViewBase from '@/components/discover/ViewBase';
import useFetchProjects from '@/composables/common/fetchProjects';

const { fetchLikedProjects, loading, pagination, projects } =
  useFetchProjects();
</script>

<template>
  <ViewBase
    v-model:pagination="pagination"
    class="liked-view"
    :loading
    :projects
    @fetch="fetchLikedProjects({ page: pagination.page })"
  >
    <template #empty>
      <div class="flex flex-col items-center gap-6 text-center">
        <MIcon color="red" name="heartFill" size="large" />
        <MText weight="bold" tag="p">プロジェクトはありません</MText>
        <MText color="secondary" size="small" tag="p"
          >一覧やプロジェクトページから登録すると、ここで見ることができます。</MText
        >
      </div>
    </template>
  </ViewBase>
</template>
