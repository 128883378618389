<script setup>
import { MCard, MIcon, MText } from '@ca-crowdfunding/makuake-ui-n';
import AppLink from '@/components/common/AppLink';
import SectionHeader from '@/components/home/SectionHeader';
import { ROUTES } from '@/consts/navigation';

const tags = [
  { icon: 'light', id: 203, label: '便利グッズ' },
  { icon: 'foldingFan', id: 183, label: '伝統' },
  { icon: 'restaurant', id: 121, label: 'レストラン' },
  { icon: 'game', id: 8, label: 'ガジェット' },
  { icon: 'japan', id: 184, label: '日本製' },
  { icon: 'coat', id: 50, label: 'ファッション' },
  { icon: 'tent', id: 134, label: 'アウトドア' },
  { icon: 'bread', id: 66, label: 'フード' },
  { icon: 'wine', id: 158, label: '日本酒' },
];
</script>

<template>
  <section class="tag-section">
    <SectionHeader title="人気のタグから探す" />
    <ul class="tag-grid gap-3 sm:gap-4">
      <li v-for="(tag, index) in tags" :key="index">
        <AppLink
          class="block"
          :index
          :to="{
            name: ROUTES.TAG.name,
            params: { tagId: tag.id },
          }"
          tracking-id="tag_popular"
        >
          <MCard class="flex" color="secondary" padding="0">
            <div class="card-sizer" />
            <div class="card-content flex items-center justify-center">
              <MIcon class="mb-2" :name="tag.icon" size="x-large" />
              <MText size="3x-small" sm="2x-small" weight="bold" tag="span">{{
                tag.label
              }}</MText>
            </div>
          </MCard>
        </AppLink>
      </li>
    </ul>
  </section>
</template>

<style scoped>
.tag-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(5.75rem, 9%), 1fr));
}

.card-sizer {
  flex: 1 0 0;
  padding-bottom: 100%;
  pointer-events: none;
}

.card-content {
  flex: 1 0 0;
  flex-direction: column;
  margin-inline-start: -100%;
}
</style>
