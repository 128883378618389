import env from '@/modules/env';

const initializeGA = () => {
  const measurementId = env.GOOGLE_ANALYTICS_ID;

  // gtag.js の読み込み
  const script1 = document.createElement('script');
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  script1.async = true;
  document.body.appendChild(script1);

  // グローバルな window.gtag 関数の定義
  const script2 = document.createElement('script');
  script2.text = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', '${measurementId}');`;
  document.body.appendChild(script2);
};

export default initializeGA;
