<script setup>
import { ref } from 'vue';

const container = ref(null);

defineProps({
  tag: {
    type: String,
    default: 'div',
  },
});

defineExpose({ container });
</script>

<template>
  <Component :is="tag" ref="container" class="scroll-container flex -mx-6 px-6">
    <slot />
  </Component>
</template>

<style lang="scss" scoped>
.scroll-container {
  overflow-x: auto;
  scroll-padding: 0 1.5rem;
  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & > :deep(*) {
    scroll-snap-align: start;
  }
}
</style>
