<script setup>
import { computed, watch } from 'vue';
import { MIcon, MButton, MText } from '@ca-crowdfunding/makuake-ui-n';
import useGaEvent from '@/composables/common/gaEvent';
import useMyTag from '@/composables/favorite/myTag';

const props = defineProps({
  tagId: {
    type: [String, Number],
    required: true,
  },
});

const { sendClickEvent } = useGaEvent();
const { handleClick, initialize, myTags } = useMyTag();

const isRegistered = computed(() =>
  myTags.value.some(tag => tag.id === props.tagId),
);

const onClick = () => {
  const prefix = isRegistered.value ? 'unregister' : 'register';
  sendClickEvent(`${prefix}_tag`);
  handleClick(props.tagId);
};

watch(
  () => props.tagId,
  () => initialize(props.tagId),
  { immediate: true },
);
</script>

<template>
  <MButton
    class="-m-2"
    color="info"
    size="x-small"
    variant="plain"
    @click="onClick"
  >
    <template #label>
      <MText
        class="flex items-center gap-1"
        color="current"
        size="small"
        sm="medium"
        tag="span"
      >
        <MIcon :name="isRegistered ? 'removeCircle' : 'addCircle'" />
        Myタグ{{ isRegistered ? '解除' : '登録' }}
      </MText>
    </template>
  </MButton>
</template>
