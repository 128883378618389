<script setup>
import { inject, computed } from 'vue';
import useGaEvent from '@/composables/common/gaEvent';
import useEnvDefinition from '@/composables/envDefinition';
import { SELECT_CONTENT_TYPES } from '@/consts/ga';

const { sendClickEvent, sendSelectContentEvent } = useGaEvent();
const { env, isProduction } = useEnvDefinition();

const cdn = env.URL_MAKUAKE_CDN;
const device = inject('device');
const prefix = computed(() =>
  ['sp', 'app'].includes(device.value) ? 'sp' : 'web',
);

const href = computed(() =>
  isProduction
    ? `https://lp-mk-2.makuake.com?utm_medium=referral&utm_source=makuake.com&utm_campaign=${prefix.value}_banner_mk`
    : '/apply/form',
);

const onClick = () => {
  sendClickEvent('application');
  sendSelectContentEvent('application', SELECT_CONTENT_TYPES.ARTICLE);
};
</script>

<template>
  <section class="application-section flex justify-center">
    <a :href="href" @click="onClick">
      <h2>
        <picture>
          <source
            height="160"
            media="(min-width: 640px)"
            :srcset="`${cdn}img/home/bn_keisai_pc.webp 1x, ${cdn}img/home/bn_keisai_pc@2x.webp 2x`"
            width="588"
          />
          <img
            alt="掲載希望の方はこちら"
            height="124"
            loading="lazy"
            :src="`${cdn}img/home/bn_keisai_sp.webp`"
            width="366"
          />
        </picture>
      </h2>
    </a>
  </section>
</template>
