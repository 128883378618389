<script setup>
import {
  MButton,
  MChip,
  MIcon,
  MSkeleton,
  MText,
} from '@ca-crowdfunding/makuake-ui-n';
import AppLink from '@/components/common/AppLink';
import ScrollContainer from '@/components/common/ScrollContainer';
import useMyTag from '@/composables/favorite/myTag';
import { ROUTES } from '@/consts/navigation';
import loggedInStatus from '@/modules/isLoggedinStatus';

const userLoggedIn = loggedInStatus.isUserLoggedin();
const { isLoaded, myTags } = useMyTag();
</script>

<template>
  <div v-if="userLoggedIn && (!isLoaded || myTags.length)" class="relative">
    <template v-if="!isLoaded">
      <ScrollContainer class="gap-2">
        <MSkeleton
          v-for="key in 4"
          :key
          height="40"
          rounded="full"
          :width="Math.floor(Math.random() * 6 + 9) * 8"
        />
      </ScrollContainer>
    </template>
    <ScrollContainer v-else class="gap-2 py-2 -my-2" tag="ul">
      <li
        v-for="(tag, index) in myTags"
        :key="tag.id"
        :class="{ 'mr-[4.5rem]': index === myTags.length - 1 }"
      >
        <AppLink
          class="flex rounded-full hover:bg-secondary hover:no-underline"
          :to="{ name: ROUTES.TAG.name, params: { tagId: tag.id } }"
          :tracking-id="`tag_${tag.id}`"
        >
          <MChip size="x-large" variant="outlined">
            <template #label>
              <MText
                class="truncate flex items-center"
                size="x-small"
                sm="small"
                weight="bold"
                tag="span"
              >
                {{ tag.name }}
                <MIcon name="right" />
              </MText>
            </template>
          </MChip>
        </AppLink>
      </li>
    </ScrollContainer>
    <div
      class="gradient-bg h-full w-28 absolute top-0 -right-6 pointer-events-none"
    />
    <MSkeleton
      v-if="!isLoaded"
      class="absolute top-0 right-0"
      height="40"
      rounded="full"
      width="64"
    />
    <MButton v-else class="absolute top-0 right-0 mt-2" size="small" rounded
      >編集</MButton
    >
  </div>
</template>

<style scoped>
.gradient-bg {
  background: linear-gradient(
    90deg,
    rgba(255 255 255 / 0%),
    rgba(255 255 255 / 80%) 16%,
    #fff 40%
  );
}

.mr-\[4\.5rem\] {
  margin-right: 4.5rem;
}

/* TODO: MSkeleton が twMarge に対応後削除 */
.absolute {
  position: absolute !important;
}

.right-0 {
  right: 0;
}

.-right-6 {
  right: -1.5rem;
}

.top-0 {
  top: 0;
}
</style>
